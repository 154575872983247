.TMS-outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #405D72; 
    
  }
  .TMS-container {
    display: flex;
    width: 90vw;
    height: 90vh;
    border: 2px solid black; /* Çerçeve */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Hafif gölge (isteğe bağlı) */
    background-color: rgb(159, 176, 176); /* Arka plan rengi (isteğe bağlı) */
    border-radius: 25px;
  }
  
  .TMS-left-panel, .TMS-right-panel{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .TMS-right-panel {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;          /* Full height of the viewport */
    position: relative;
  
  }
 
  .TMS-layout-panel{
    border-right: 2px solid rgb(0, 0, 0);
    width: 35vw;
  }
  
  .image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 20px;
    border: 2px solid black;
  }

  .password-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(159, 176, 176); /* Siyah ve şeffaf arka plan */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px); /* Arka planı bulanıklaştır */
  }
  
  .password-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .TMS-outer-container.show {
    visibility: visible; /* Şifre doğru girildikten sonra görünür */
  }