.MenuContainer {
    flex: 0 0 20%; /* Genişlik yüzde 20 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #e0e0e0; /* İsteğe bağlı arka plan rengi */
}

.LogoContainer {
    flex: 0 0 80%; /* Genişlik yüzde 80 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #c0c0c0; /* İsteğe bağlı arka plan rengi */
}

.Divider {
    width: 2px; /* Çizginin genişliği */
    background-color: black; /* Çizginin rengi */
    height: 100%; /* Çizginin yüksekliği */
}

.OuterContainer {
    display: flex;
    width: 100vw; /* Tüm ekran genişliği */
    height: 100vh; /* Tüm ekran yüksekliği */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: rgb(182, 192, 192);
}
.logoImage {
    width: 50%; /* Genişlik */
    height: auto; /* Yükseklik */
    margin-bottom: 40%; /* Alt boşluğu otomatik yaparak logoyu üste sabitler */
    align-self: center; /* Logoyu üstte tutar */
    border-radius: 15px;
}

.LoopImage{
    width: 50%; 
    transition: opacity 1s ease-in-out; /* Geçiş efekti, 1 saniyede tamamlanır */
    position: absolute;
}
.LoopImage.hidden {
    opacity: 0; /* Görünmez */
}

.LoopImage.visible {
    opacity: 1; /* Görünür */
}
