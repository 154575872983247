.outer-containerSC {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #405D72; 
   
    
  }
  .containerSC {
    display: flex;
    width: 95vw;
    height: 95vh;
    border: 2px solid black; /* Çerçeve */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Hafif gölge (isteğe bağlı) */
    background-color: rgb(159, 176, 176); /* Arka plan rengi (isteğe bağlı) */
    border-radius: 25px;
  }
  
  .left-panelSC, .right-panelSC{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .right-panelSC {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;          /* Full height of the viewport */
    position: relative;
  
  }
  .left-panelSC {
    border-right: 2px solid rgb(0, 0, 0);
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto; /* Add vertical scrolling */
    max-height: 100vh; /* Limit height to viewport height */
    position: relative;
  }
   
  .layout-panelSC{
    border-right: 2px solid rgb(0, 0, 0);
    width: 15vw;
  }
  
  .imageSC {
    max-width: 80%;
    max-height: 80%;
    border-radius: 20px;
    border: 2px solid black;
  }
  
  .force-flexSC {
    display: flex !important;
    align-items: center !important; /* Adjust alignment as needed */
    gap: 0.5rem !important; /* Adds space between icon and text */
  }
  .TreeTable {
    justify-content: center !important;
    align-items: center !important;
    align-self: center;
    flex: 2 !important;
  }