  .UE-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #405D72; 
    
  }
  .UE-container {
    display: flex;
    width: 90vw;
    height: 90vh;
    border: 2px solid black; /* Çerçeve */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Hafif gölge (isteğe bağlı) */
    background-color: rgb(159, 176, 176); /* Arka plan rengi (isteğe bağlı) */
    border-radius: 25px;
  }
  
  .UE-left-panel, .UE-right-panel{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .UE-right-panel {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;          /* Full height of the viewport */
    position: relative;
  
  }
